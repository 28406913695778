<template>
	<div>
		<a-modal width="1000px" v-model="visible" title="图片库" ok-text="确认选择" cancel-text="取消" @cancel="handleCancel" @ok="handleOk">
		     <a-spin :spinning="loading">
			  <div>
				  <div>
					  <a-upload
					      name="file"
					     :showUploadList="false"
					     :customRequest="uploadImg"
					     :beforeUpload="beforeUpload"
					    >
					      <a-button> <a-icon type="upload" />点击上传</a-button>
					    </a-upload>
				  </div>
				  
				  <div class="flex wrap mt30">
					  
					  <div v-for="(item,index) in datas" :key="index" :class="{on:selectIds.indexOf(item.url) > -1}" class="album-item">
						  <div class="content">
							  <img  @click="selectIndex(item.url)" :src="item.url" />
						  </div>
						  <div class="del" @click="delAct(index)"><a-icon type="delete" />删除</div>
					  </div>
					  
				  </div>
				  
				  <div class="mt20">
					  <a-pagination @change="handleTableChange" :current="pagination.current" :pageSize="pagination.pageSize" :total="pagination.total" show-less-items />
				  </div>
			  </div>
			  </a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default{
		props:{
			type:{
				type:String,
				default:'single', //single multi
			}
		},
		data(){
			return  {
				visible:false,
				selectIds:[],
				loading:false,
				pagination: {
					current: 1,
					pageSize: 18, //每页中显示10条数据
					total: 0,
				},
				datas: [],
			}
		},
		created(){
			this.visible = true;
			this.selectIds = new Array;
			this.getList();
		},
		methods:{
			handleCancel(){
				this.visible = false;
				this.$emit('closed');
			},
			delAct(index){
				this.$confirm({
					title: '确定要删除吗？',
					content: '删除该图片',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						if(this.loading==true) return;
						this.loading=true;
						let imgUrl = this.datas[index].url;
						this.$http.papi('platform/delUploadPhoto',{
							imgId:this.datas[index].img_id
						}).then(res=>{
							let newArray = JSON.parse(JSON.stringify(this.selectIds));
							if(this.type == 'single'){
								if(newArray.length != 0){
									if(newArray[0] == imgUrl){
										newArray = new Array;
									}
								}
							}else{
								let myindex = newArray.indexOf(imgUrl);
								if(myindex > -1){
									newArray.splice(myindex,1);
								}
							}
							this.selectIds = newArray;
							this.loading=false;
							this.getList();
							
						}).catch(res=>{
							console.log(res);
							this.loading=false;
						})
						
					},
					onCancel: () => {
						
					},
				});
			},
			handleOk(){
				
				if(this.selectIds.length == 0) return false;
				if(this.type == 'single'){
			
					this.$emit('input',this.selectIds[0]);
					this.$emit('change',this.selectIds[0]);
				}else{
					
					this.$emit('input',this.selectIds);
					this.$emit('change',this.selectIds);
				}
				this.handleCancel();
			},
			handleTableChange(pagination) {
				this.pagination.current = pagination;
				this.getList();
			},
			selectIndex(index){
				let newArray = JSON.parse(JSON.stringify(this.selectIds));
				if(this.type == 'single'){
					if(newArray.length == 0){
						newArray.push(index);
					}else{
						if(newArray[0] == index){
							newArray = new Array;
						}else{
							newArray[0] = index;
						}
						
					}
				}else{
					let myindex = newArray.indexOf(index);
					if(myindex > -1){
						newArray.splice(myindex,1);
					}else{
						newArray.push(index);
					}
				}
				this.selectIds = newArray;
			},
			getList(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.papi('platform/getUploadPhotoList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current
				}).then(res=>{
					this.datas = res.datas;
					this.pagination.total = res.total;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			uploadImg(e) {
				let file = e.file;
				this.loading = true;
					this.$http.upload(file).then(res=>{
						this.loading = false;
						this.handleTableChange(1);
					}).catch(res=>{
						this.$message.error('上传图片出错了');
						this.loading = false;
					});
			},
			
			beforeUpload(file) {
				console.log(file);
				const isImg = (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif');
				if (!isImg) {
					this.$message.error('只能允许上传图片');
					return false;
				}
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error('图片最大为2M');
					return false;
				}
				return isImg && isLt2M;
			},
			
			
		}
	}
</script>

<style>
	.album-item {
	   width: 148px;
	   height: 148px;
		background: rgba(94, 64, 255, 0.06);
		position: relative;
		cursor: pointer;
		border-radius: 4px;
		margin-right: 10px;
		margin-bottom: 10px;
		overflow: hidden;
	 }
	 .album-item .content{
		 width: 100%;
		 height: 100%;
		 display: flex;
		 justify-content: center;
		 align-items: center;
	 }
	 .album-item img{
	 	  object-fit: cover;
	 	  width: 134px;
	 	  height: 134px;
	 }
	 .album-item.on{
		 border:2px dashed #4772FF;
	 }
	 .album-item .del{
		 position: absolute;
		 width: 100%;
		 height: 20px;
		 background: rgba(0,0,0,.3);
		 text-align: end;
		 padding-right: 10px;
		 line-height: 20px;
		 right: 0;
		 bottom: 0;
		 color: #fff;
	 }
</style>