<template>
	<div class="pd30">
		<a-spin :spinning="loading">
			<div class="flex alcenter space">
				<div class="ft20 cl-black cl-main ftw500">{{getTitle}}</div>
				<div>
					<a-button @click="$router.go(-1)">返回</a-button>
				</div>
			</div>

			<div class="mt20">
				<div class="bg-w pd40" style="min-height: 800px;">
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						<a-form-model-item required label="上传图片" >
							<div class="ft12 ftw400 cl-notice">建议上传240x240px，最多上传9张</div>
							<div class="mt10 ml20">
								<upload-list v-model="form.photos" :size="9"></upload-list>
							</div>
						</a-form-model-item>


						<a-form-model-item required label="文字内容">
							<a-textarea  v-model="form.info" :rows="11" />
						</a-form-model-item>



						<a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
						      <a-button type="primary" @click="onSubmit" :loading="confirmLoading">
						        保存发布
						      </a-button>
						</a-form-model-item>
					</a-form-model>
				</div>
			</div>

		</a-spin>
	</div>
</template>

<script>
	import uploadList from '../../components/upload/list.vue';
	export default{
		components:{
			uploadList,
		},
		data(){
			return{
				loading:false,
				confirmLoading:false,
				labelCol: { span: 4 },
				wrapperCol: { span: 20 },
				look_id:0,
				form:{
					photos:[],
					info:'',
				}
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.look_id=0;
			} else {
				this.look_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		computed:{
			getTitle(){
				if(this.look_id==0){
					return '添加相册';
				}else{
					return '编辑相册';
				}
			}
		},
		methods:{
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showLook',{
					look_id:this.look_id
				}).then(res=>{
					this.loading=false;
					if(res.detail){
						this.form.shop_id=res.detail;
					}
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$router.go(-1);
				})
			},

			onSubmit(){



				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/saveLook',{
					look_id:this.look_id,
					photos:this.form.photos,
					info:this.form.info,
				}).then(res=>{
					this.$message.success('保存成功');
					this.$router.go(-1);
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
		}
	}
</script>

<style>

</style>
